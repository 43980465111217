import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import callApi from "../../api/callApi";

import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { toast } from "react-toastify";

const GameServer = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gamelist, setGameList] = useState([]);
  const [gameServerList, setgameServerList] = useState([]);
  const [selectedGame, setSelectedGame] = useState([]);

  useEffect(() => {
    fetchGameServer();
  }, []);
  useEffect(() => {
    if (isModalOpen) fetchGame();
  }, [isModalOpen]);
  const { Option } = Select;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const submitObj = values._id
        ? { ...values, games: selectedGame, _id: values._id }
        : { ...values, games: selectedGame };

      callApi(
        values._id ? `/game-server/${values._id}` : "/game-server",
        values._id ? "PATCH" : "POST",
        submitObj,
        true
      )
        .then((x) => {
          fetchGameServer();
        })
        .catch((error) => toast.error(error?.message));
      form.resetFields();
      setIsModalOpen(false);
    } catch (errorInfo) {
      toast.error("Failed", errorInfo);
    }
  };
  const fetchGameServer = async () => {
    try {
      callApi("/game-server", "GET", null, true).then((x) => {
        setgameServerList(x);
      });
    } catch (errorInfo) {
      toast.error("Failed", errorInfo);
    }
  };
  const fetchGame = async () => {
    callApi("/game", "GET", null, true)
      .then((x) => {
        setGameList(x);
      })
      .catch((error) => toast.error(error?.message));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    form.resetFields();
  };
  const onProtocolChange = (value) => {
    switch (value) {
      case "http":
        form.setFieldsValue({ note: "Hi,http" });
        break;
      case "https":
        form.setFieldsValue({ note: "Hi, https" });
        break;

      default:
    }
  };
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  const onGameChange = (value) => {
    setSelectedGame(value);
  };
  const deleteGameServer = async (item) => {
    callApi(`/game-server/${item._id}`, "DELETE", null, true)
      .then(() => fetchGameServer())
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button type="primary" onClick={showModal}>
          Create
        </Button>
      </div>

      <List
        grid={{
          gutter: 16,
          xxl: 4,
          xl: 4,
          lg: 3,
          md: 3,
          sm: 1,
          xs: 1,
        }}
        dataSource={gameServerList}
        renderItem={(item) => (
          <List.Item>
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    marginTop: 10,
                  }}
                >
                  <div className="text-container">{item?.host}</div>
                  <div className="text-container">{item?.port}</div>
                  <div className="text-container">{item?.protocol}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "20%",
                    position: "absolute",
                    right: 0,
                    top: 15,
                  }}
                >
                  <div
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setIsModalOpen(true);
                      form.setFieldsValue({
                        host: item?.host,
                        port: item?.port,
                        protocol: item?.protocol,
                        games: item?.games,
                        _id: item?._id,
                      });
                    }}
                  >
                    <EditTwoTone twoToneColor="#0b2161" />
                  </div>
                  <Popconfirm
                    placement="top"
                    title={"Are you sure want to delete Game Server"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteGameServer(item)}
                  >
                    <div>
                      <DeleteTwoTone twoToneColor="#A93226 " />
                    </div>
                  </Popconfirm>
                </div>
              </div>
            </Card>
          </List.Item>
        )}
      />
      <Modal
        title={
          form.getFieldValue("_id")
            ? "Update Game Server"
            : "Create Game Server"
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 4,
          }}
          labelAlign="left"
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Host"
            name="host"
            rules={[
              {
                required: true,
                message: "Please input your host!",
              },
              {
                pattern:
                  /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*|\d{1,3}(\.\d{1,3}){3})$/,
                message:
                  "Invalid host! Format should be ip or name of a domain",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Port"
            name="port"
            rules={[
              {
                required: true,
                message: "Please input your port!",
              },
              {
                pattern: /^0:\d{1,5}$/,
                message: "Invalid port number! Format should be 0:nnnn.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="protocol"
            label="Protocol"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Protocol"
              onChange={onProtocolChange}
              allowClear
            >
              <Option value="http">http</Option>
              <Option value="https">https</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="games"
            label="Game"
            rules={[
              {
                required: true,
                message: "Please select your Game!",
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select Games"
              onChange={onGameChange}
            >
              {gamelist?.map((game, i) => {
                return (
                  <Option key={i} value={game._id}>
                    {game.description}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name={"_id"} />
        </Form>
      </Modal>
    </div>
  );
};
export default GameServer;
