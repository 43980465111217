import { Button, Card, Checkbox, Col, Form, Input, Layout, Row } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React from "react";
import { LoginOutlined } from "@ant-design/icons";
import callApi from "../../api/callApi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useMobileScreen } from "../../hooks/useMobileScreen";

import { toast } from "react-toastify";
const Login = () => {
  const isMobile = useMobileScreen();
  const navigate = useNavigate();

  const { changeData } = useAuth();

  const onFinish = (values) => {
    callApi("/user/login", "POST", values, false)
      .then((x) => {
        changeData("token", x?.token);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message || "Internal Server Error!");
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    toast.error(errorInfo);
  };
  const cardTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <LoginOutlined style={{ color: "#0B2161" }} />
        </div>
        <div style={{ marginLeft: 10 }}>
          <p style={{ color: "#0B2161" }}>{"Login"}</p>
        </div>
      </div>
    );
  };
  return (
    <>
      <Layout style={{ height: "100%", overflow: "hidden" }}>
        <Header></Header>
        <Content>
          <Row>
            {isMobile ? (
              <></>
            ) : (
              <Col
                // span={12}
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                style={{
                  backgroundColor: "#0b0c28",
                  height: "100vh",
                }}
              >
                <img
                  src="/assets/game.jpeg"
                  style={{ width: "100%", height: "100%" }}
                />
              </Col>
            )}
            <Col
              // span={12}
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#0b0c28",
                height: "100vh",
              }}
            >
              <Card
                title={cardTitle()}
                style={
                  isMobile
                    ? {
                        width: "90%",
                        height: "50%",
                        padding: 10,
                      }
                    : {
                        width: 500,
                        height: 350,
                        padding: 10,
                      }
                }
              >
                <Form
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  style={{
                    maxWidth: 600,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      // onClick={() => onFinish()}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default Login;
