import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedLayout = ({ children }) => {
  const token = localStorage?.getItem("token");
  const outlet = useOutlet();
  console.log(token);
  if (!token) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};
