import {
  MenuFoldOutlined,
  VideoCameraOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import GameServer from "../gameServer/gameServer";
import GameScreen from "../game/game";
import { useMobileScreen } from "../../hooks/useMobileScreen";
const { Header, Sider, Content } = Layout;
const Dashboard = () => {
  const isMobile = useMobileScreen();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    item: {
      props: {
        content: () => <GameScreen />,
      },
    },
  });

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout style={{ overflow: "hidden" }}>
      <Sider
        collapsible={true}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="sm"
        collapsedWidth={isMobile ? 0 : ""}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["1"]}
          items={[
            {
              key: "1",
              icon: <PictureOutlined />,
              label: "Game",
              content: () => <GameScreen />,
            },
            {
              key: "2",
              icon: <VideoCameraOutlined />,
              label: "Game server",
              content: () => <GameServer />,
            },
            {
              key: "3",
              icon: <LogoutOutlined />,
              label: "Logout",
              content: () => {
                navigate("/", { replace: true });
                localStorage.clear();
              },
            },
          ]}
          onSelect={(item) => setSelectedItem(item)}
        ></Menu>
      </Sider>
      <Layout style={{ overflow: "hidden", maxHeight: "100vh" }}>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div
            style={{
              display: "flex",
              height: 45,
              alignItems: "center",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <p>Welcome to Admin Dashboard</p>
          </div>
        </Header>
        <Content
          style={{
            overflow: "scroll",
            margin: "24px 16px",
            padding: 24,
            minHeight: "100vh",
            background: colorBgContainer,
          }}
        >
          {selectedItem?.item?.props?.content()}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
