import { useEffect, useState } from "react";
import useWindowSize from "./useWindow";

export function useMobileScreen() {
  const { width } = useWindowSize();
  const [screenWidth, setScreenWidth] = useState(false);

  useEffect(() => {
    setScreenWidth(width < 600 ? true : false);
  }, [width]);

  return screenWidth;
}
