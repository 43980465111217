import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Radio,
  Upload,
} from "antd";
import {
  DeleteTwoTone,
  EditTwoTone,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./game.css";
import callApi from "../../api/callApi";

import { toast } from "react-toastify";

const { Item } = Form;
const GameScreen = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gameList, setGameList] = useState([]);

  const [radioButtonvalue, setRadioButtonValue] = useState("url");

  useEffect(() => {
    fetchGame();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      let obj = {
        ...values,
        images: [
          {
            data: values.URL,
            primay: true,
            type: values.ImageType,
          },
        ],
      };

      let imageObj = {
        path: values.path,
        friendlyName: values.friendlyName,
        description: values.description,

        images: [
          {
            data: values?.BASE64?.map((e) => e.thumbUrl).toString(),
            primary: true,
            type: values.ImageType,
          },
        ],
      };
      const submitObj = values._id ? { ...obj, _id: values._id } : obj;
      const submitImgObj = values._id
        ? { ...imageObj, _id: values._id }
        : imageObj;
      if (values.ImageType === "URL") {
        callApi(
          values._id ? `/game/${values._id}` : "/game",
          values._id ? "PATCH" : "POST",
          submitObj,
          true
        )
          .then(() => {
            fetchGame();
          })
          .catch((error) => toast.error(error?.message));
      } else {
        callApi(
          values._id ? `/game/${values._id}` : "/game",
          values._id ? "PATCH" : "POST",
          values._id ? submitImgObj : imageObj,
          true
        )
          .then(() => {
            fetchGame();
          })
          .catch((error) => toast.error(error?.message));
      }

      form.resetFields();
      setIsModalOpen(false);
    } catch (errorInfo) {
      toast.warn(errorInfo);
    }
  };
  const fetchGame = async () => {
    callApi("/game", "GET", null, true)
      .then((x) => {
        setGameList(x);
      })
      .catch((error) => toast.error(error?.message));
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    form.resetFields();
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    form.resetFields();
    toast.warn("Failed", errorInfo);
  };

  const onChange = (e) => {
    setRadioButtonValue(e.target.value);
  };

  const deleteGame = async (item) => {
    callApi(`/game/${item._id}`, "DELETE", null, true)
      .then(() => {
        fetchGame();
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            form.setFieldsValue({
              params: [""],
            });
            showModal();
          }}
        >
          Create
        </Button>
      </div>

      <List
        grid={{
          gutter: 16,
          xxl: 4,
          xl: 4,
          lg: 3,
          md: 3,
          sm: 1,
          xs: 1,
        }}
        dataSource={gameList}
        renderItem={(item) => (
          <List.Item>
            <Card className="ant-card" bordered={false}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    marginTop: 10,
                  }}
                >
                  <div className="text-container">{item?.friendlyName}</div>
                  <div className="text-container">{item?.path}</div>
                  <div className="text-container">{item?.description}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "20%",
                    position: "absolute",
                    right: 0,
                    top: 15,
                  }}
                >
                  <div
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      form.setFieldsValue({
                        path: item?.path,
                        friendlyName: item?.friendlyName,
                        description: item?.description,
                        params: item?.params,
                        ImageType: item?.images[0]?.type,
                        URL: item?.images[0]?.data,

                        _id: item._id,
                      });
                      setIsModalOpen(true);
                    }}
                  >
                    <EditTwoTone twoToneColor="#0b2161" />
                  </div>
                  <Popconfirm
                    placement="top"
                    title={"Are you sure want to delete the Game"}
                    onConfirm={() => deleteGame(item)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div>
                      <DeleteTwoTone twoToneColor="#A93226 " />
                    </div>
                  </Popconfirm>
                </div>
              </div>
            </Card>
          </List.Item>
        )}
      />
      <Modal
        title={form.getFieldValue("_id") ? "Update Game" : "Create Game"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Item
            name="path"
            label="Path"
            rules={[
              {
                required: true,
                message: "Please input your path!",
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="friendlyName"
            label="Friendly Name"
            rules={[
              {
                required: true,
                message: "Please input your friendly Name!",
              },
            ]}
          >
            <Input />
          </Item>
          <Item name="description" label="Description">
            <TextArea rows={4} />
          </Item>
          <Form.List name="params">
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Item
                      label={index === 0 ? "Params" : ""}
                      required={false}
                      key={field.key}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",

                          height: 40,
                        }}
                      >
                        <Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input param's name or delete this field.",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Params"
                            style={{
                              width: "90%",
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Please add params!",
                              },
                            ]}
                          />
                        </Item>
                        {index === fields.length - 1 ? (
                          <Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{
                                marginLeft: "15%",
                                marginTop: 22,
                              }}
                              icon={<PlusOutlined />}
                            ></Button>

                            <Form.ErrorList errors={errors} />
                          </Item>
                        ) : (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        )}
                      </div>
                    </Item>
                  ))}
                </>
              );
            }}
          </Form.List>
          <Item name="ImageType" label="Image Type" onChange={onChange}>
            <Radio.Group>
              <Radio value="URL">Url</Radio>
              <Radio value="BASE64">BASE64</Radio>
            </Radio.Group>
          </Item>

          {form.getFieldValue("ImageType") === "URL" ? (
            <Item
              name="URL"
              label="Url"
              rules={[
                {
                  required: true,
                  message: "Please input your url!",
                },
              ]}
            >
              <Input />
            </Item>
          ) : (
            <Item
              name="BASE64"
              label="images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload listType="picture-card">
                <div>
                  {form.getFieldValue("URL") ? (
                    <img
                      src={form.getFieldValue("URL")}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </>
                  )}
                </div>
              </Upload>
            </Item>
          )}

          <Item name={"_id"} />
        </Form>
      </Modal>
    </div>
  );
};
export default GameScreen;
