import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./uselocalStorage";

const AuthContext = createContext({
  user: null,
});

export const AuthProvider = ({ children, tokenData }) => {
  const [storedValue, setValue] = useLocalStorage("token", tokenData);
  const navigate = useNavigate();
  // call this function when you want to authenticate the user
  // const login = async (data) => {
  //   setUser(data);
  //   navigate("/dashboard");
  // };
  // call this function to sign out logged in user
  const changeData = (keyName, value) => {
    setValue({ keyName, value });
  };
  const logout = () => {
    // setUser(null);
    navigate("/", { replace: true });
  };
  const value = useMemo(
    () => ({
      storedValue,
      // login,
      // logout,
      changeData,
    }),
    [storedValue]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
