import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { App } from "./App";
import { ConfigProvider } from "antd";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const queryClient = new QueryClient();
root.render(
  <StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#0B2161",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={App} />
      </QueryClientProvider>
      <ToastContainer position="top-center" theme="dark" />
    </ConfigProvider>
  </StrictMode>
);
