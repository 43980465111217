import axios from "axios";
import config from "../config";

const callApi = async (
  endpoint,
  method = "GET",
  data = null,
  isToken = false
) => {
  const token = localStorage.getItem("token");
  const url = `${config.baseUrl}${endpoint}`;

  const headers = {
    "Content-Type": "application/json",
  };

  if (isToken) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const config = {
      method,
      url,
      headers,
    };
    if (method !== "DELETE") {
      config.data = data;
    }
    const response = await axios(config);

    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in Node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    throw error;
  }
};

export default callApi;
