import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import Login from "./pages/login/login";
import { AuthLayout } from "./components/authLayout";
import Dashboard from "./pages/dashboard";
import { ProtectedLayout } from "./components/protectedLayout";

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = localStorage.getItem("token");
      console.log(user);
      resolve(user);
    }, 3000)
  );

export const App = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route path="/" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedLayout>
            <Dashboard />
          </ProtectedLayout>
        }
      ></Route>
    </Route>
  )
);
